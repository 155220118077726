import { Component, Injector } from '@angular/core';
import { FormPaneComponent } from '@ade/core/src/panes';
import { AuthenticationService, InjectionService } from '@ade/core/src/services';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'tools-dashboard',
    templateUrl: 'tools.component.html'
})
export class ToolsDashboardComponent extends FormPaneComponent {
    public authentication: AuthenticationService;
    public constructor(
        protected injection: InjectionService,
        protected injector: Injector
    ) {
        super(injection, injector);
        this.authentication = this.getService(AuthenticationService);
    }
}
