import {
    CaseFactory,
    ContactFactory
} from '../models';

export const ModelConfigDef = {
    models: [
        CaseFactory,
        ContactFactory
    ]
};
