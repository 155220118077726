import {
    BuildReleaseFactory,
    BuildSourceFactory,
    DecodedCorefileFactory,
    DecodedDmrawFactory,
    DirectoryMetaFactory,
    ExportedTenantsFactory,
    ImageNumberFactory,
    ImagePatchFactory,
    JiraIssueFactory,
    TaskJiraIssueFactory,
    TaskFactory,
    VersionFactory,
    RmaCertificateFactory,
    SpaCodeFactory,
    LicenseKeyUpgradeFactory 
} from '../models';

export const ModelConfigDef = {
    models: [
        BuildReleaseFactory,
        BuildSourceFactory,
        DecodedCorefileFactory,
        DecodedDmrawFactory,
        DirectoryMetaFactory,
        ExportedTenantsFactory,
        JiraIssueFactory,
        TaskJiraIssueFactory,
        TaskFactory,
        VersionFactory,
        ImageNumberFactory,
        ImagePatchFactory,
        RmaCertificateFactory,
        SpaCodeFactory,
        LicenseKeyUpgradeFactory
    ]
};
