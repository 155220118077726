import { ApplyMixinProperties, ApplyMixins } from '@ade/core/src/mixins';
import { BaseEsRecord, BaseEsFactory } from '@ade/core/src/models/base.factory';
import { TimestampMixin } from '@ade/core/src/models/mixins';

export class LicenseKeyUpgradeRecord extends ApplyMixins(BaseEsRecord, [
    TimestampMixin,
]) {
    public account_id: string;
    public ap_count: number;
    public cloud_product_type: string;
    public created_ts: number;
    public deleted: boolean;
    public end_date: number;
    public flexera_id: string;
    public id: string;
    public license_code: string;
    public license_type: string;
    public license_url: string;
    public lku_number: string;
    public name: string;
    public name_text: string;
    public partner_id: string;
    public product: object;
    public product_code: string;
    public product_family: string;
    public registration_code: string;
    public sfdc_id: string;
    public sfdc_sync_paused: boolean;
    public sfdc_sync_ts: number;
    public so_id: string;
    public soli_id: string;
    public start_date: number;
    public status: string;
    public temp_sn: string;
    public updated_ts: number;
}

export class LicenseKeyUpgradeFactory extends BaseEsFactory {
    public static model = 'sfdc-license-key-upgrade';
    public static endpoint = '/api/sfdc/license-key-upgrade/';
    public static recordClass = LicenseKeyUpgradeRecord;
    public static properties: object = ApplyMixinProperties(
        Object.assign({
            account_id: { type: ['string', 'null'] },
            ap_count: { type: ['number', 'null'] },
            cloud_product_type: { type: ['string', 'null'] },
            created_ts: { type: ['number', 'null'] },
            deleted: { type: ['boolean', 'null'] },
            end_date: { type: ['number', 'null'] },
            flexera_id: { type: ['string', 'null'] },
            id: { type: ['string', 'null'] },
            license_code: { type: ['string', 'null'] },
            license_type: { type: ['string', 'null'] },
            license_url: { type: ['string', 'null'] },
            lku_number: { type: ['string', 'null'] },
            name: { type: ['string', 'null'] },
            name_text: { type: ['string', 'null'] },
            partner_id: { type: ['string', 'null'] },
            product: { 
                type: ['object', 'null'],
                properties: {
                    id: { type: ['string', 'null'] }
                }
            },
            product_code: { type: ['string', 'null'] },
            product_family: { type: ['string', 'null'] },
            registration_code: { type: ['string', 'null'] },
            sfdc_id: { type: ['string', 'null'] },
            sfdc_sync_paused: { type: ['boolean', 'null'] },
            sfdc_sync_ts: { type: ['number', 'null'] },
            so_id: { type: ['string', 'null'] },
            soli_id: { type: ['string', 'null'] },
            start_date: { type: ['number', 'null'] },
            status: { type: ['string', 'null'] },
            temp_sn: { type: ['string', 'null'] },
            updated_ts: { type: ['number', 'null'] },
        }, BaseEsFactory.properties),
        [
            TimestampMixin
        ]
    );

    public static relationEndpoints: { [s: string]: object } = {
    };
}
