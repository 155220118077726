import { ApplyMixinProperties, ApplyMixins } from '@ade/core/src/mixins';
import { BaseEsRecord, BaseEsFactory } from '@ade/core/src/models/base.factory';
import { SoftDeleteMixin, TimestampMixin } from '@ade/core/src/models/mixins';

export class SpaCodeRecord extends BaseEsRecord {
    public sfdc_id: string;
    public sfdc_sync_paused: boolean;
    public sfdc_sync_ts: number;
    public created_ts: number;
    public updated_ts: number;
    public acc_hierarchy: object;
    public account: {
        domains: string[] | null,  // Handle array of domains
        id: string | null,         // Allow id to be null
        name: string | null,       // Allow name to be null
        parent: any | null
    };
    public cloud_instance: object;
    public distributor: {
        domains: string[] | null,  // Handle array of domains
        id: string | null,         // Allow id to be null
        name: string | null,       // Allow name to be null
        parent: any | null
    };
    public in_account_id: string;
    public is_success: boolean;
    public lku_line_items: Array<{
        serialNumber: string,
        primaryPartnerName: string,
        primaryPartnerId: string,
        primaryDistributorName: string,
        primaryDistributorId: string,
        newserialNumber: string,
        message: string,
        isSuccess: boolean,
        id: string,
        endUserName: string,
        endUserId: string
    }>;
    public partner: {
        domains: string[] | null,  // Handle array of domains
        id: string | null,         // Allow id to be null
        name: string | null,       // Allow name to be null
        parent: any | null
    };
    public so_line_items: Array<{
        supportSerials: string,
        registeredquantity: number,
        quantity: number,
        productFamily: string,
        productDescription: string,
        productCode: string,
        productCategory: string,
        primarySerial: string | null,
        partquantity: number,
        newsupportSerials: string,
        newprimarySerial: string,
        message: string,
        isSuccess: boolean,
        isPrimaryRequired: boolean,
        id: string
    }>;
    public status: {
        isDifferent: boolean,
        isLinked: boolean,
        isUsed: boolean,
        isValid: boolean,
        message: string
    };
    public code_type: string;
}

export class SpaCodeFactory extends BaseEsFactory {
    public static model = 'sfdc-spa-code';
    public static endpoint = '/api/sfdc/spa-code/';
    public static recordClass = SpaCodeRecord;
    public static properties: object = (
        Object.assign({
            sfdc_id: { type: ['string', 'null'] },
            sfdc_sync_paused: { type: ['boolean', 'null'] },
            sfdc_sync_ts: { type: ['number', 'null'] },
            created_ts: { type: ['number', 'null'] },
            updated_ts: { type: ['number', 'null'] },
            acc_hierarchy: { type: ['object', 'null'] },
            account: {
                type: ['object', 'null'],
                properties: {
                    domains: { type: ['null', 'array'] },  // Handle domains array
                    id: { type: ['string', 'null'] },      // Allow id to be null
                    name: { type: ['string', 'null'] },    // Allow name to be null
                    parent: { type: ['null', 'object'] }
                }
            },
            cloud_instance: { type: ['object', 'null'] },
            distributor: {
                type: ['object', 'null'],
                properties: {
                    domains: { type: ['null', 'array'] },  // Handle domains array
                    id: { type: ['string', 'null'] },      // Allow id to be null
                    name: { type: ['string', 'null'] },    // Allow name to be null
                    parent: { type: ['null', 'object'] }
                }
            },
            in_account_id: { type: 'string' },
            is_success: { type: 'boolean' },
            lku_line_items: {
                type: ['array'],
                items: {
                    type: 'object',
                    properties: {
                        serialNumber: { type: 'string' },
                        primaryPartnerName: { type: 'string' },
                        primaryPartnerId: { type: 'string' },
                        primaryDistributorName: { type: 'string' },
                        primaryDistributorId: { type: 'string' },
                        newserialNumber: { type: 'string' },
                        message: { type: 'string' },
                        isSuccess: { type: 'boolean' },
                        id: { type: 'string' },
                        endUserName: { type: 'string' },
                        endUserId: { type: 'string' }
                    }
                }
            },
            partner: {
                type: ['object', 'null'],
                properties: {
                    domains: { type: ['null', 'array'] },  // Handle domains array
                    id: { type: ['string', 'null'] },      // Allow id to be null
                    name: { type: ['string', 'null'] },    // Allow name to be null
                    parent: { type: ['null', 'object'] }
                }
            },
            so_line_items: {
                type: ['array'],
                items: {
                    type: 'object',
                    properties: {
                        supportSerials: { type: 'string' },
                        registeredquantity: { type: 'number' },
                        quantity: { type: 'number' },
                        productFamily: { type: 'string' },
                        productDescription: { type: 'string' },
                        productCode: { type: 'string' },
                        productCategory: { type: 'string' },
                        primarySerial: { type: ['string', 'null'] },
                        partquantity: { type: 'number' },
                        newsupportSerials: { type: 'string' },
                        newprimarySerial: { type: 'string' },
                        message: { type: 'string' },
                        isSuccess: { type: 'boolean' },
                        isPrimaryRequired: { type: 'boolean' },
                        id: { type: 'string' }
                    }
                }
            },
            status: {
                type: 'object',
                properties: {
                    isDifferent: { type: 'boolean' },
                    isLinked: { type: 'boolean' },
                    isUsed: { type: 'boolean' },
                    isValid: { type: 'boolean' },
                    message: { type: 'string' }
                }
            },
            code_type: { type: 'string' }
        }, BaseEsFactory.properties),
        []
    );

    public static relationEndpoints: { [s: string]: object } = {};
}
