import { BaseEsRecord, BaseEsFactory } from '@ade/core/src/models/base.factory';

export class ContactRecord extends BaseEsRecord {
    public name: string;
    public email: string;

    public sfdc_id: string;
    public account: object;
    public display: string;
}

export class ContactFactory extends BaseEsFactory {
    public static model = 'sfdc-contact';
    public static endpoint = '/api/sfdc/contact/';
    public static recordClass = ContactRecord;
    public static properties: object = Object.assign({
        name: { type: ['string', 'null'] },
        email: { type: ['string', 'null'] },

        sfdc_id: { type: ['string', 'null'] },
        account: { type: ['object', 'number', 'string', 'null'], properties: {
            id: { type: 'number' },
            name: { type: ['string', 'null'] },
            type: { type: ['string', 'null'] }
        } },

        display: { type: ['string', 'null'] }
    }, (BaseEsFactory).properties);

    public static relationEndpoints: { [s: string]: object } = {
    };
}
